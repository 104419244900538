import { PopoverBanner } from './PopupBanner.types'

export const POPOVER_BANNER_DATA: {
  [key: string]: PopoverBanner
} = {
  ru: {
    text: `
      <p><i>«Медуза» — это вы!</i> Уже три года мы работаем благодаря вам, и&nbsp;только для вас. Помогите нам прожить вместе с вами 2025 год!</p>
      <p>Если вы находитесь не в России, оформите ежемесячный донат&nbsp;— а&nbsp;мы сделаем все, чтобы миллионы людей получали наши новости. Мы верим, что независимая информация помогает принимать правильные решения даже в самых сложных жизненных обстоятельствах. Берегите себя!</p>
      `,
    switcher: {
      monthly: 'каждый месяц',
      once: 'один раз'
    },
    button: 'Поддержать',
    variants: {
      monthly: [
        {
          text: '20',
          url: {
            usd:
              ' https://buy.stripe.com/aEU9DNaM94re1vqcNc?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025',
            eur:
              'https://buy.stripe.com/3cs8zJ8E10aY3DyaF3?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025'
          }
        },
        {
          text: '25',
          url: {
            usd:
              'https://buy.stripe.com/5kA6rBf2p2j6b607sH?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025',
            eur:
              'https://buy.stripe.com/9AQ03dcUh0aY4HCaF0?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025'
          }
        },
        {
          description: 'другая сумма',
          isCustom: true,
          url: {
            usd:
              'https://support.meduza.io/?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025#support',
            eur:
              'https://support.meduza.io/?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025#support'
          }
        }
      ],
      once: [
        {
          text: '20',
          url: {
            usd:
              'https://buy.stripe.com/cN203d1bz5vib606oG?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025',
            eur:
              'https://buy.stripe.com/14k3fp5rP2j6gqk7sL?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025'
          }
        },
        {
          text: '25',
          url: {
            usd:
              'https://buy.stripe.com/cN2g2b7zX6zmde8eVb?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025',
            eur:
              'https://buy.stripe.com/eVa6rBg6tbTGb60bJ2?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025'
          }
        },
        {
          description: 'другая сумма',
          isCustom: true,
          url: {
            usd:
              'https://buy.stripe.com/cN2bLV6vT7Dqfmg7sP?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025',
            eur:
              'https://buy.stripe.com/14kaHRaM9bTG4HCdRe?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025'
          }
        }
      ]
    }
  },
  en: {
    text: `
      <p>Meduza survived 2024 thanks to its readers!</p>
      <p>Let’s stick together for&nbsp;2025.</p>
      <p>The world is at a crossroads today, and quality journalism will help shape the decades to come. The real stories must be told in any cost. Please support Meduza by signing up for a recurring donation.</p>
    `,
    switcher: {
      monthly: 'Monthly',
      once: 'One-time'
    },
    button: 'Support',
    variants: {
      monthly: [
        {
          text: '20',
          url: {
            usd:
              ' https://buy.stripe.com/aEU9DNaM94re1vqcNc?utm_source=meduza_en&utm_medium=pop_up&utm_campaign=ny2025',
            eur:
              'https://buy.stripe.com/3cs8zJ8E10aY3DyaF3?utm_source=meduza_en&utm_medium=pop_up&utm_campaign=ny2025'
          }
        },
        {
          text: '25',
          url: {
            usd:
              'https://buy.stripe.com/5kA6rBf2p2j6b607sH?utm_source=meduza_en&utm_medium=pop_up&utm_campaign=ny2025',
            eur:
              'https://buy.stripe.com/9AQ03dcUh0aY4HCaF0?utm_source=meduza_en&utm_medium=pop_up&utm_campaign=ny2025'
          }
        },
        {
          description: 'Any amount',
          isCustom: true,
          url: {
            usd:
              'https://support.meduza.io/en?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025&product=true#support',
            eur:
              'https://support.meduza.io/en?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025&product=true#support'
          }
        }
      ],
      once: [
        {
          text: '20',
          url: {
            usd:
              'https://buy.stripe.com/cN203d1bz5vib606oG?utm_source=meduza_en&utm_medium=pop_up&utm_campaign=ny2025',
            eur:
              'https://buy.stripe.com/14k3fp5rP2j6gqk7sL?utm_source=meduza_en&utm_medium=pop_up&utm_campaign=ny2025'
          }
        },
        {
          text: '25',
          url: {
            usd:
              'https://buy.stripe.com/cN2g2b7zX6zmde8eVb?utm_source=meduza_en&utm_medium=pop_up&utm_campaign=ny2025',
            eur:
              'https://buy.stripe.com/eVa6rBg6tbTGb60bJ2?utm_source=meduza_en&utm_medium=pop_up&utm_campaign=ny2025'
          }
        },
        {
          description: 'Any amount',
          isCustom: true,
          url: {
            usd:
              'https://buy.stripe.com/cN2bLV6vT7Dqfmg7sP?utm_source=meduza_en&utm_medium=pop_up&utm_campaign=ny2025',
            eur:
              'https://buy.stripe.com/14kaHRaM9bTG4HCdRe?utm_source=meduza_en&utm_medium=pop_up&utm_campaign=ny2025'
          }
        }
      ]
    }
  }
}
