import React, { useState } from 'react'
import { makeClassName } from '@client/utils/makeClassName'

import { PopoverBanner } from './PopupBanner.types'

import styles from './PopoverBannerContent.module.css'

const Icon = () => (
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.002 40.974c-.034-9.362-7.619-16.94-16.97-16.94 9.372 0 16.97-7.613 16.97-17.003.034 9.362 7.62 16.94 16.972 16.94-9.373 0-16.972 7.612-16.972 17.003Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 36.001c6.595-6.643 6.59-17.365-.023-23.978C18.605 18.65 29.361 18.64 36 12c-6.595 6.644-6.59 17.366.023 23.979-6.628-6.628-17.384-6.618-24.023.022Z"
      fill="currentColor"
    />
  </svg>
)

export const PopoverBannerContent = ({
  content,
  handleDismiss
}: {
  content: PopoverBanner
  handleDismiss: () => void
}) => {
  const { text, switcher, button, variants } = content

  const [selectedVariant, setSelectedVariant] = useState('monthly')
  const [currency, setCurrency] = useState<'usd' | 'eur'>('usd')

  const variantsList = variants[selectedVariant]

  const handleButtonClick = () => {
    handleDismiss()
  }

  return (
    <div className={styles.root}>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: text }}
      />

      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.variant}>
            <button
              type="button"
              className={makeClassName([
                [styles.variantButton, true],
                [styles.isActive, selectedVariant === 'monthly']
              ])}
              onClick={() => setSelectedVariant('monthly')}
            >
              {switcher.monthly}
            </button>
            <button
              type="button"
              className={makeClassName([
                [styles.variantButton, true],
                [styles.isActive, selectedVariant === 'once']
              ])}
              onClick={() => setSelectedVariant('once')}
            >
              {switcher.once}
            </button>
          </div>
          <div className={styles.currency}>
            <button
              type="button"
              className={makeClassName([
                [styles.currencyButton, true],
                [styles.isActive, currency === 'eur']
              ])}
              onClick={() => setCurrency('eur')}
            >
              €
            </button>
            <button
              type="button"
              className={makeClassName([
                [styles.currencyButton, true],
                [styles.isActive, currency === 'usd']
              ])}
              onClick={() => setCurrency('usd')}
            >
              $
            </button>
          </div>
        </div>

        <div className={styles.variantsList}>
          {variantsList.map(({ text, description, url, isCustom }, index) => (
            <div className={styles.variantsItem} key={index}>
              <div className={styles.variantsItemContent}>
                {text && (
                  <span className={styles.variantsItemText}>{text}</span>
                )}

                {isCustom && (
                  <div className={styles.variantsItemIcon}>
                    <Icon />
                  </div>
                )}
                {description && (
                  <span className={styles.variantsItemDescription}>
                    {description}
                  </span>
                )}
              </div>

              <div className={styles.variantsItemButton}>
                <a
                  href={url[currency]}
                  onClick={handleButtonClick}
                  target="_blank"
                  rel="noreferrer"
                >
                  {button}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
